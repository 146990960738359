import { AppProps, ErrorBoundary, ErrorComponent, ErrorFallbackProps } from "@blitzjs/next"
import { Analytics } from "@vercel/analytics/react"
import { withBlitz } from "app/blitz-client"
import { AuthenticationError, AuthorizationError } from "blitz"
import { GoogleAnalytics } from "nextjs-google-analytics"
import { Suspense } from "react"

import NiceModal from "@ebay/nice-modal-react"
import { Toaster } from "react-hot-toast"

const black = {
  "50": "#E6E6E6",
  "100": "#CCCCCC",
  "200": "#999999",
  "300": "#666666",
  "400": "#333333",
  "500": "#000000",
  "600": "#000000",
  "700": "#000000",
  "800": "#000000",
  "900": "#000000",
}

const fullBlack = {
  "50": "#000000",
  "100": "#000000",
  "200": "#000000",
  "300": "#000000",
  "400": "#333333",
  "500": "#000000",
  "600": "#000000",
  "700": "#000000",
  "800": "#000000",
  "900": "#000000",
}

const red = {
  DEFAULT: "#EF4444",
  "50": "#F7A2A2",
  "100": "#F58F8F",
  "200": "#F26A6A",
  "300": "#EF4444",
  "400": "#E71414",
  "500": "#B30F0F",
  "600": "#800B0B",
  "700": "#4C0707",
  "800": "#180202",
  "900": "#000000",
}
const gray = {
  "50": "#FAFAFA",
  "100": "#F7F7F7",
  "200": "#F0F0F0",
  "300": "#E8E8E8",
  "400": "#E0E0E0",
  "500": "#D8D8D8",
  "600": "#ADADAD",
  "700": "#828282",
  "800": "#575757",
  "900": "#2B2B2B",
}

const light: ColorScheme = {
  id: "light",
  type: "light",
  colors: {
    bg: {
      base: "#f8f8f8",
      fill: colors.gray["600"],
    },
    text: {
      foreground: colors.gray["900"],
      muted: colors.gray["900"],
    },
    primary: black,
    secondary: gray,
    neutral: colors.gray,
    error: {
      foreground: colors.red["900"],
      muted: colors.red["100"],
    },
  },
}

export const bee: ColorScheme = {
  id: "light",
  type: "light",
  colors: {
    bg: {
      base: "#fafafa",
      fill: colors.warmGray["100"],
    },
    text: {
      foreground: colors.warmGray["900"],
      muted: colors.warmGray["700"],
    },
    primary: colors.amber,
    neutral: colors.warmGray,
  },
}

const theme = extendTheme({
  cursor: "pointer",
  colorSchemes: {
    light,
  },
})

import { colors, ColorScheme, extendTheme, Spinner, VechaiProvider } from "@vechaiui/react"
import { Seo } from "app/components/Seo"
import { useRouter } from "next/router"

function RootErrorFallback({ error }: ErrorFallbackProps) {
  const router = useRouter()
  if (error instanceof AuthenticationError) {
    router.push("/login")
    return <div>Error: You are not authenticated</div>
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent
        statusCode={(error as any)?.statusCode || 400}
        title={error.message || error.name}
      />
    )
  }
}

const excludedStyleRoutes = ["/"]

async function loadStyles(router: any) {
  if (!excludedStyleRoutes.includes(router.pathname)) {
    try {
      //@ts-ignore
      await import("../styles/customstyles.css").then()
      //@ts-ignore
      await import("../styles/globals.css").then()
    } catch (error) {
      console.error("Failed to load styles:", error)
    }
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()

  loadStyles(router)

  return (
    //@ts-ignore
    <Suspense
      fallback={
        <span className="flex items-center justify-center h-screen max-w-sm mx-auto">
          <Spinner />
        </span>
      }
    >
      <ErrorBoundary FallbackComponent={RootErrorFallback}>
        <VechaiProvider theme={theme} colorScheme="light">
          <Toaster
            toastOptions={{
              position: "bottom-center",
              style: { background: "black", color: "white" },
            }}
            position="top-center"
          />
          <NiceModal.Provider>
            {/* @ts-ignore */}
            <GoogleAnalytics strategy="lazyOnload" trackPageViews />
            <Analytics />
            {router.pathname !== "/" && <Seo />}
            {getLayout(<Component {...pageProps} />)}
          </NiceModal.Provider>
        </VechaiProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

export default withBlitz(MyApp)

