import { DefaultSeo, NextSeo } from "next-seo"
import { OpenGraphMedia } from "next-seo/lib/types"

const og_url = "https://diagram.imgix.net/og.png?s=73c7043f27f51ef1f45d40d570456dba"
export const Seo = () => {
  return (
    <DefaultSeo
      title="Diagram"
      description="Generative Creativity"
      twitter={{
        handle: "@diagram",
        site: "https://diagram.com",
        cardType: "summary",
      }}
      openGraph={{
        images: [
          {
            url: og_url,
            width: 512,
            height: 512,
            alt: "Og Image",
            type: "image/png",
          },
        ],
      }}
    />
  )
}

export const CustomSeo = ({
  title = "Diagram",
  description = "Generative Creativity",
  images = [],
}: {
  title: string
  description?: string
  images?: ReadonlyArray<OpenGraphMedia>
}) => {
  return (
    <NextSeo
      title={title}
      description={description}
      twitter={{
        handle: "diagram",
        site: "https://diagram.com",
        cardType: "summary",
      }}
      openGraph={{
        images: [
          ...images,
          {
            url: og_url,
            width: 512,
            height: 512,
            alt: "Og Image",
            type: "image/png",
          },
        ],
      }}
    />
  )
}
