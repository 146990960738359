// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const SENTRY_ENVIRONMENT =
  process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

if (!["production", "staging", "development"].includes(SENTRY_ENVIRONMENT)) {
  throw new Error(`Invalid SENTRY_ENVIRONMENT ${JSON.stringify(SENTRY_ENVIRONMENT)}`)
}
Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://b52103b4ae4d4a1292f3ccd0134b0bb3@o1255838.ingest.sentry.io/4504081718771712",
  environment: SENTRY_ENVIRONMENT,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
